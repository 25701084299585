<template>
	<div class="col cc success-main" v-show="pageShow">
		<el-image :src="require('@/pc/assets/ask/success.png')" class="success-img"></el-image>
		<div class="title">问题提交成功</div>
		<div class="col cc tips">
			<div>您的问题已成功发布至「{{ detail.category_name }}」分类下，新提出的问题，将会显示在分类列表的最前列。</div>
			<div>问题发布成功后，7日内回答者可作答，请及时采纳问题的最佳答案，对作答者进行奖励；</div>
			<div>如7日内无人作答或无满意答案，提问者可选择问题延期，或关闭问题。</div>
		</div>
		<div class="detail-title">问题详情：</div>
		<div class="row detail">
			<div class="item">问题ID：{{ detail.id }}</div>
			<div class="item">发布人：{{ detail.nickname }}</div>
			<div class="item">发布时间：{{ detail.create_time_text }}</div>
			<div class="item">回答时限：{{ detail.end_time_text }}</div>
			<!-- <div class="item">问题悬赏：{{ detail.type == 1 ? detail.point + '积分' : detail.amount + '元' }}</div> -->
		</div>
		<div class="row status-view cb">
			<div class="row status-item">
				<el-image :src="require('@/pc/assets/ask/success-item.png')" class="success-item"></el-image>
				<div>创建问题</div>
			</div>
			<div class="line"></div>
			<div class="row status-item">
				<el-image :src="require('@/pc/assets/ask/success-item.png')" class="success-item"></el-image>
				<div>设置问题发布</div>
			</div>
			<div class="line"></div>
			<div class="row status-item-b">
				<div class="row cc num">3</div>
				<div>问题解答</div>
			</div>
			<div class="line"></div>
			<div class="row status-item-b">
				<div class="row cc num">4</div>
				<div>采纳问题</div>
			</div>
		</div>
		<div class="row btn-group">
			<el-button type="primary" @click="navDetail()">查看问题</el-button>
			<el-button @click="navHome()">返回首页</el-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			pageShow: false,
			detail: {}
		};
	},
	created() {
		this.$service.post('question/detail', { id: this.$route.query.id }).then(res => {
			this.detail = res.data;
			this.pageShow = true;
		});
	},
	methods: {
		navDetail() {
			this.$router.replace({
				path: '/ask/detail',
				query: { id: this.$route.query.id }
			});
		},
		navHome() {
			this.$router.replace({
				path: '/'
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './success.less';
</style>
